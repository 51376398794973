import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link } from "../../components/al_components/ALLink";
import * as Styles from "./styles.module.css";
import SEO from "../../components/seo";
import Breadcrumb from "../../components/Breadcrumb";
import IconArrow from "../../components/icons/svgs/arrow.svg";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";

// Images
import diamondImage from "../../images/CAREPAGE-DIAMONDS-ana-luisa-jewelry-necklace-pendant-necklace-diamond-jewelry-diamond-necklace.jpeg";
import goldPlatedImage from "../../images/CAREPAGE-GOLDPLATED-ana-luisa-jewelry-necklace-chain-necklace-curb-chain-necklace-jusuf.jpeg";
import sterlingSilverImage from "../../images/CAREPAGE-STERLINGSILVER-ana-luisa-jewelry-bracelet-chain-bracelet-sterling-silver-chain-bracelet-michelle-choi.jpeg";

function MaterialCard({ name, subtitle, desc, linkText, linkUrl, image }) {
  const subtitleMobile = <h4 className={`al_h4 ${Styles.card__bottom__subtitle}`}>{subtitle}</h4>;
  const subtitleDesktop = <h3 className={`al_h3 ${Styles.card__bottom__subtitle}`}>{subtitle}</h3>;
  const subtitleContent = useResponsiveWithHydrationFix(subtitleMobile, subtitleDesktop);

  return (
    <div className={Styles.card}>
      <div className={Styles.card__top}>
        <img src={image} alt="Jewelry care" className={Styles.card__top__image} />
        <h2 className={`al_h2 al_h2--white al_caps ${Styles.card__top__name}`}>{name}</h2>
      </div>
      <div className={Styles.card__bottom}>
        {subtitleContent}
        <h5 className={`al_subtext ${Styles.card__bottom__desc}`}>{desc}</h5>
        <Link to={linkUrl} className={`al_link ${Styles.card__bottom__link}`}>
          {linkText}
          <IconArrow width="7" height="13" />
        </Link>
      </div>
    </div>
  );
}

MaterialCard.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

function HowToTakeCarePage({ path }) {
  return (
    <div className={Styles.main}>
      <SEO
        title="How to take care of your jewelry"
        description="Discover the ultimate guide on how to take care of your Analuisa jewelry collection. From cleaning tips to storage solutions, learn how to keep your favorite pieces looking their best for years to come. Shop now and elevate your jewelry care routine with Analuisa."
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <Breadcrumb pageTitle="How to take care of your jewelry" />
      <h1 className={`al_subtext al_caps ${Styles.main__subtitle}`}>
        How to take care of your jewelry
      </h1>
      <h3 className={`al_h1 ${Styles.main__title}`}>Our materials</h3>
      <div className={Styles.main__grid}>
        <MaterialCard
          name="Gold Plated Brass"
          subtitle="Your gold-plated pieces will shine for longer with the right care and attention."
          desc="Gently rub your gold-plated jewelry with a soft jewelry cloth to restore its shine. After every use, clean your jewelry with a cotton ball or a very soft cloth to remove any dust and dirt. Plated pieces can be prone to oxidation, so we recommend removing before you swim, shower, or apply lotion, makeup, or perfume. To minimize scratches, store your piece in your Ana Luisa pouch or in a separate compartment in your jewelry box."
          linkText="Shop our Gold Plated pieces"
          linkUrl="/collections/gold-plated-jewelry"
          image={goldPlatedImage}
        />
        <MaterialCard
          name="Sterling Silver"
          subtitle="Chosen for its strength and beauty, sterling silver can give you a lifetime of gleam, or cultivate a lovely patina all on its own."
          desc="Avoid exposing your sterling silver piece to chlorinated water and to cleaning products that contain bleach. Use a soft cloth to clean your silver piece. To minimize scratches and nicks, store it in your Ana Luisa pouch or in a separate compartment in your jewelry box."
          linkText="Shop our Sterling Silver pieces"
          linkUrl="/collections/sterling-silver"
          image={sterlingSilverImage}
        />
        <MaterialCard
          name="Sustainable Diamonds"
          subtitle="Sustainably-grown diamonds are forever too. With the right care, they’ll keep their just-out-of-the-box sparkle for life."
          desc="Clean your diamond piece with just warm water every day to keep its shine and sparkle, or soak it in a gentle degreasing solution (we recommend water with a few drops of mild dish soap). After soaking, use a soft, clean toothbrush to remove any remaining dirt."
          linkText="Shop our Diamond Collection"
          linkUrl="/collections/diamond-jewelry"
          image={diamondImage}
        />
      </div>
    </div>
  );
}

export default HowToTakeCarePage;
