// extracted by mini-css-extract-plugin
export var card = "styles-module--card--74d18";
export var card__bottom = "styles-module--card__bottom--8d5a5";
export var card__bottom__desc = "styles-module--card__bottom__desc--7b53b";
export var card__bottom__link = "styles-module--card__bottom__link--d7f4c";
export var card__bottom__subtitle = "styles-module--card__bottom__subtitle--7dec5";
export var card__top = "styles-module--card__top--69df1";
export var card__top__image = "styles-module--card__top__image--947c5";
export var card__top__name = "styles-module--card__top__name--0ddf9";
export var main = "styles-module--main--5bd49";
export var main__grid = "styles-module--main__grid--f3417";
export var main__subtitle = "styles-module--main__subtitle--a9624";
export var main__title = "styles-module--main__title--fa19c";